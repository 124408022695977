"use client";

// React Imports
import { useEffect, useState } from "react";

// Third-party Imports
import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "cmdk";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { FaSearch } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import { Avatar } from "src/components/avatar";
import { useAddAppointmentModal } from "src/context/appointmentAddModal";
import { subscribeToPublicAvailability } from "src/redux/reducers/availability";
import styled from "styled-components";

const StyledDiv = styled.div`
  cursor: pointer;
  margin: 10px;
`;

const StyledAvatar = styled(Avatar)`
  margin-right: 10px;
`;
// Helper function to filter and limit results per section based on the number of sections
const renderTimeslots = (
  t,
  availabilityArray,
  locationMap,
  setModalData,
  setOpen
) => {
  if (!availabilityArray || !availabilityArray.length) return null;

  const combinedAvailability = {};

  // Combine availability by date and time slot
  availabilityArray.forEach((user) => {
    const { availability, firstName, lastName, avatar, id } = user;

    if (!availability) return;

    Object.keys(availability).forEach((date) => {
      if (!combinedAvailability[date]) {
        combinedAvailability[date] = {};
      }

      Object.keys(availability[date]).forEach((time) => {
        const momentDateTime = moment(date + " " + time, "YYYY-MM-DD HH:mm");

        if (!combinedAvailability[date][time]) {
          combinedAvailability[date][time] = [];
        }

        if (momentDateTime.isSameOrAfter(moment())) {
          // Push the user info along with the slot data (booked, locationId, etc.)
          combinedAvailability[date][time].push({
            ...availability[date][time],
            firstName,
            lastName,
            avatar,
            userId: id,
            date: date,
            time: time,
            dateTime: moment(date + " " + time, "YYYY-MM-DD HH:mm").toDate(),
          });
        }
      });
    });
  });

  // Sort the dates and render them
  const sortedDates = Object.keys(combinedAvailability).sort(
    (a, b) => new Date(a) - new Date(b)
  );

  return sortedDates.map((date) => {
    const timeSlots = combinedAvailability[date];
    const days = [
      "",
      t("Monday"),
      t("Tuesday"),
      t("Wednesday"),
      t("Thursday"),
      t("Friday"),
      t("Saturday"),
      t("Sunday"),
    ];
    const dayOfWeek = moment(date, "YYYY-MM-DD").isoWeekday();
    // Filter and sort the available time slots
    const sortedTimeSlots = Object.keys(timeSlots)
      .filter((time) => timeSlots[time].some((slot) => !slot.booked)) // Filter out fully booked times
      .sort((a, b) => {
        const [hourA, minA] = a.split(":").map(Number);
        const [hourB, minB] = b.split(":").map(Number);
        return hourA !== hourB ? hourA - hourB : minA - minB;
      });

    if (sortedTimeSlots.length === 0) {
      // If no available time slots for this date, show "no available places"
      return (
        <CommandGroup
          heading={days[dayOfWeek] + ", " + date}
          key={date}
          className="px-1"
        >
          <CommandItem key={`${date}-none`}>
            <div>{t("No available places")}</div>
          </CommandItem>
        </CommandGroup>
      );
    }

    return (
      <CommandGroup
        heading={days[dayOfWeek] + ", " + date}
        key={date}
        className="px-1"
      >
        {sortedTimeSlots.map((time) => {
          const slots = timeSlots[time]
            .map((slot) => ({
              ...slot,
              title: `${slot.firstName} ${slot.lastName}`,
              locationName: `${locationMap[slot.locationId]}`,
              searchTerm: (
                `${slot.firstName} ${slot.lastName} (${locationMap[slot.locationId]})` +
                " " +
                slot.date +
                " " +
                slot.time +
                " " +
                days[dayOfWeek]
              ).toLowerCase(),
            }))
            .filter((slot) => !slot.booked); // Hide booked slots

          return (
            <CommandItem key={time}>
              <div style={{ width: "100%" }}>
                <strong>{time}:</strong>
                {slots.map((slot, idx) => (
                  <StyledDiv
                    key={idx}
                    onClick={() => {
                      setModalData({
                        defaultLocationId: slot.locationId,
                        defaultDate: slot.dateTime,
                        defaultUserId: slot.userId,
                        suggestLocationAndRoom: true,
                      });
                      setOpen(false);
                    }}
                  >
                    <StyledAvatar img={slot.avatar} />
                    {slot.title} - {slot.locationName}
                    <div style={{ display: "none" }}>{slot.searchTerm}</div>
                  </StyledDiv>
                ))}
              </div>
            </CommandItem>
          );
        })}
      </CommandGroup>
    );
  });
};

const NavSearch = () => {
  // States
  const [open, setOpen] = useState(false);
  const { setModalData } = useAddAppointmentModal();
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const [locationMap, setLocationMap] = useState({});
  const availabilityStore = useSelector((state) => state.availability);
  const locationsStore = useSelector((state) => state.locations);

  useEffect(() => {
    subscribeToPublicAvailability();
  }, []);
  // Toggle the menu when ⌘K is pressed
  useEffect(() => {
    const down = (e) => {
      if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setOpen((open) => !open);
      }
    };

    document.addEventListener("keydown", down);

    return () => document.removeEventListener("keydown", down);
  }, []);

  // Reset the search value when the menu is closed
  useEffect(() => {
    if (!open && searchValue !== "") {
      setSearchValue("");
    }
  }, [open]);

  useEffect(() => {
    const newLocationMap = locationsStore.data.reduce((p, c) => {
      p[c.id] = c.name;
      return p;
    }, {});
    setLocationMap(newLocationMap);
  }, [locationsStore.updatedAt]);
  return (
    <>
      <div className="d-flex align-items-center" onClick={() => setOpen(true)}>
        <Button
          className="btn-icon"
          color="primary"
          onClick={() => setOpen(true)}
        >
          <FaSearch size={16} />
        </Button>
      </div>

      <CommandDialog
        open={open}
        onOpenChange={setOpen}
        filter={(value, search) => {
          if (value.includes(search)) return 1;
          return 0;
        }}
      >
        <div className="d-flex align-items-center pt-2 px-2">
          <CommandInput
            value={searchValue}
            placeholder={t("Search")}
            onValueChange={setSearchValue}
          />
        </div>
        <CommandList>
          <CommandEmpty>{t("No results")}</CommandEmpty>
          {renderTimeslots(
            t,
            availabilityStore.publicAvailability,
            locationMap,
            setModalData,
            setOpen
          )}
        </CommandList>
      </CommandDialog>
    </>
  );
};

export default NavSearch;
