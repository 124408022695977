import { createSlice } from "@reduxjs/toolkit";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { orderBy } from "lodash";
import type { Secret, TenantSettings } from "practicare/types/tenant.model";
import { db } from "../../config/firebase";
import { store } from "../store";

let tenantSub: any = null;
let globalSecretsSub: any = null;
const userSecretSub: any = {
  userId: "",
  sub: null,
};

export interface TenantState {
  data: TenantSettings | null;
  globalSecrets: Secret[];
  templates: any[];
  userSecrets: Secret[];
  updatedAt: string;
}

const initialState: TenantState = {
  data: null,
  globalSecrets: [],
  templates: [],
  userSecrets: [],
  updatedAt: Date.now().toString(),
};

export const tenantSlice = createSlice({
  name: "tenant",
  initialState: initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload;
      state.updatedAt = Date.now().toString();
    },
    setGlobalSecrets(state, action) {
      state.globalSecrets = action.payload;
      state.updatedAt = Date.now().toString();
    },
    setUserSecrets(state, action) {
      state.userSecrets = action.payload;
      state.updatedAt = Date.now().toString();
    },
    setTemplates(state, action) {
      state.templates = action.payload;
      state.updatedAt = Date.now().toString();
    },
  },
});

export const subscribeToUserSecrets = (userId: string) => {
  if (userSecretSub.sub) {
    if (userSecretSub.userId !== userId) {
      userSecretSub.sub();
    } else {
      return;
    }
  }
  userSecretSub.userId = userId;
  try {
    const queryObj = [where("isDeleted", "!=", true)];
    queryObj.push(where("userId", "==", userId));
    userSecretSub.sub = onSnapshot(
      query(collection(db, "secrets"), ...queryObj),
      (data) => {
        const secrets: Secret[] = [];
        data.forEach((doc) => {
          secrets.push({
            ...(doc.data() as Secret),
            id: doc.id,
          });
        });
        store.dispatch(
          tenantSlice.actions.setUserSecrets(orderBy(secrets, "name"))
        );
      }
    );
  } catch (e) {
    console.error(e);
  }
};

export const subscribeToGlobalSecrets = () => {
  if (globalSecretsSub) {
    return;
  }
  try {
    const queryObj = [where("isDeleted", "!=", true)];
    globalSecretsSub = onSnapshot(
      query(collection(db, "secrets"), ...queryObj),
      (data) => {
        const secrets: Secret[] = [];
        data.forEach((doc) => {
          secrets.push({
            ...(doc.data() as Secret),
            id: doc.id,
          });
        });
        store.dispatch(
          tenantSlice.actions.setGlobalSecrets(
            orderBy(secrets, "name").filter((s) => s.type === "GLOBAL")
          )
        );
      }
    );
  } catch (e) {
    console.error(e);
  }
};

export const subToTenant = () => {
  if (tenantSub) {
    return;
  }
  try {
    tenantSub = onSnapshot(query(collection(db, "tenants")), (data) => {
      if (data.docs.length !== 0) {
        store.dispatch(tenantSlice.actions.setData(data.docs[0].data()));
      }
    });
  } catch (e) {
    console.error(e);
  }
};

let templatesSub: any = null;
export const subscribeToTenantTemplates = () => {
  if (templatesSub) {
    return;
  }
  templatesSub = onSnapshot(
    query(collection(db, "tenantTemplates")),
    (data) => {
      const templatesRawData = data.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      store.dispatch(
        tenantSlice.actions.setTemplates(
          orderBy(templatesRawData, ["label"], ["asc"])
        )
      );
    }
  );
};
export default tenantSlice.reducer;
