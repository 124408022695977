// ** Redux Imports
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  collection,
  DocumentData,
  limit,
  onSnapshot,
  orderBy,
  query,
  QueryDocumentSnapshot,
  where,
} from "firebase/firestore";
import _, { isEmpty } from "lodash";
import moment from "moment-timezone";
import { Calendar } from "react-feather";
import { Link } from "react-router-dom";
import { db } from "../../config/firebase";
import { store } from "../store";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const changesets = require("diff-json");
// ** Constants
const TITLE_CHANGE_DETAILS = "Zmiana szczegółów wizyty";
const PENDING_STATUS = "Pending";

// ** Types
interface TimelineObject {
  id: string;
  before?: Record<string, any>;
  originalBefore?: any;
  after?: Record<string, any>;
  originalAfter?: any;
  changedAt?: Date | string;
  type: string;
  updatedBy: any;
  createdBy: any;
  changer: Changer;
  isCompletedAt?: Date | null;
  noPermission?: boolean;
  customContent?: string;
}

interface Changer {
  id: string;
  display: string;
  isCustomer?: boolean;
}

export interface FeedState {
  transactions: QueryDocumentSnapshot<DocumentData>[];
  timeline: any[];
  updatedAt: string;
  hasMore: any;
  loading: boolean;
  newTransactions: boolean; // New property to manage new transactions state
}

// ** Initial State
const initialState: FeedState = {
  transactions: [],
  timeline: [],
  updatedAt: Date.now().toString(),
  hasMore: null,
  loading: true,
  newTransactions: false,
};

// ** Translate Function
const translate = (type: string, value: string): string => {
  const dictionary: any = {
    "Rodzaj płaności": {
      ONLINE: "Online",
      OFFLINE: "Stacjonarnie",
      BANK_TRANSFER: "Przelew bankowy",
      CREDIT_CARD: "Karta kredytowa",
      CASH: "Gotówka",
      comment: "Komentarz terapeuty",
      "N/A": "N/A",
    },
    "Status płatności": {
      COMPLETED_AUTOMATICALLY: "Opłacono",
      COMPLETED_MANUALLY: "Opłacono",
      PENDING: "Oczekuje",
    },
    Status: {
      SCHEDULED: "Zaplanowana",
      COMPLETED: "Zrealizowana",
      CANCELED: "Odwołana",
      "CANCELED-PAID": "Odwołana poza terminem",
    },
    "Powód odwołania": {
      CUSTOMER: "Odwołanie klienta",
      APPOINTMENT_MOVED: "Przełożenie wizyty na inny termin",
      THERAPIST_ILLNESS: "Choroba terapeuty",
      THERAPIST_VACATION: "Urlop terapeuty",
      PROCESS_TERMINATION: "Zakończenie procesu",
      CUSTOMER_ABSENT: "Klient nie pojawił się na wizycie",
    },
    "Powód zmiany terminu": {
      CUSTOMER: "Odwołanie klienta",
      THERAPIST_ILLNESS: "Choroba terapeuty",
      THERAPIST_VACATION: "Urlop terapeuty",
      THERAPIST_CHANGE: "Przelozona przez terapeutę",
      THERAPY_RESIGNATION: "Rezygnacja z terapii",
      PROCESS_TERMINATION: "Zakończenie procesu",
      CUSTOMER_ABSENT: "Klient nie pojawił się na wizycie",
    },
  };

  return dictionary[type] ? dictionary[type][value] : value;
};

// ** Helper Functions
const createTimelineObject = (
  doc: QueryDocumentSnapshot<DocumentData>
): TimelineObject => {
  const timelineObject: TimelineObject = {
    id: doc.id,
    before: {},
    after: {},
    originalBefore: doc.data().before,
    originalAfter: doc.data().after,
    changedAt: doc.data().changedAt?.toDate(),
    type: "APPOINTMENT",
    updatedBy: doc.data().after?.updatedBy,
    createdBy: doc.data().before?.createdBy,
    changer: {
      id: "",
      display: "",
      isCustomer: false,
    },
  };

  if (!isEmpty(doc.data().before)) {
    timelineObject.before = {
      Klient:
        doc.data().before.customer?.lastName &&
        doc.data().before.customer?.firstName
          ? `${doc.data().before.customer.lastName} ${doc.data().before.customer.firstName}`
          : "",
      Lokalizacja: doc.data().before.location?.name || "",
      Pokój: doc.data().before.room?.name || "",
      "Komentarz terapeuty": doc.data().before.comment || "",
      Status: doc.data().before.appointmentStatus || "",
      "Powód odwołania": doc.data().before.cancelReason || "",
      "Powód zmiany terminu": doc.data().before.dateChangeReason || "",
      Wariant: doc.data().before.variant?.variant?.name || "",
      "Status płatności": doc.data().before.paymentStatus || "",
      "Rodzaj płaności": doc.data().before.paymentType || "",
      "Data i godzina wizyty": moment(
        doc.data().before.dateTime?.toDate()
      ).format("YYYY-MM-DD HH:mm"),
    };
  }

  if (!isEmpty(doc.data().after)) {
    timelineObject.after = {
      Klient:
        doc.data().after?.customer?.lastName &&
        doc.data().after?.customer?.firstName
          ? `${doc.data().after.customer.lastName} ${doc.data().after.customer.firstName}`
          : "",
      Lokalizacja: doc.data().after.location?.name || "",
      "Komentarz terapeuty": doc.data().after.comment || "",
      Pokój: doc.data().after.room?.name || "",
      Status: doc.data().after.appointmentStatus || "",
      Wariant: doc.data().after.variant?.variant?.name || "",
      "Powód odwołania": doc.data().after.cancelReason || "",
      "Powód zmiany terminu": doc.data().after.dateChangeReason || "",
      "Status płatności": doc.data().after.paymentStatus || "",
      "Rodzaj płaności": doc.data().after.paymentType || "",
      "Data i godzina wizyty": moment(
        doc.data().after.dateTime?.toDate()
      ).format("YYYY-MM-DD HH:mm"),
    };
  }

  if (timelineObject.originalAfter.updatedBy) {
    if (timelineObject.originalAfter.updatedBy === "CUSTOMER") {
      timelineObject.changer.isCustomer = true;
      timelineObject.changer.id = timelineObject.after?.customer?.id;
      timelineObject.changer.display = `${timelineObject.originalAfter.customer?.lastName} ${
        timelineObject.originalAfter.customer?.firstName
      }`;
    } else {
      timelineObject.changer.id = timelineObject.originalAfter?.updatedBy?.id;
      timelineObject.changer.display = `${timelineObject.originalAfter.updatedBy?.lastName} ${
        timelineObject.originalAfter.updatedBy?.firstName
      }`;
    }
  } else {
    timelineObject.changer.id = timelineObject.originalAfter?.createdBy?.id;
    timelineObject.changer.display = `${timelineObject.originalAfter?.createdBy?.lastName} ${timelineObject.originalAfter?.createdBy?.firstName}`;
  }
  return timelineObject;
};

const generateTimelineContentAppointmentChange = (
  timelineObject: TimelineObject
): any[] => {
  const timelineContent: any[] = [];

  const differenceList = changesets.diff(
    timelineObject.before,
    timelineObject.after
  );

  if (differenceList.length > 0) {
    timelineContent.push({
      title: (
        <div>
          <span>
            {Object.keys(timelineObject.originalBefore).length !== 0
              ? TITLE_CHANGE_DETAILS
              : "Nowa wizyta"}
          </span>
          {timelineObject.changedAt && (
            <span>
              {" - "}
              <Link
                target="_blank"
                rel="noopener noreferrer"
                className="text-body ml-1 practicare-link"
                to={
                  timelineObject.changer.id !== "system"
                    ? `/${timelineObject.changer.isCustomer ? "customers" : "users"}/${timelineObject.changer.id}`
                    : ""
                }
              >
                {timelineObject.changer.display}
              </Link>
            </span>
          )}
        </div>
      ),
      icon: <Calendar size={14} />,
      meta: timelineObject.changedAt
        ? moment(timelineObject.changedAt).format("YYYY-MM-DD HH:mm")
        : PENDING_STATUS,
      dateTime: timelineObject.changedAt,
      customContent: (
        <div>
          {Object.keys(timelineObject.originalBefore).length !== 0 && (
            <ul className="list-unstyled">
              <li>
                Terapeuta: {timelineObject.originalBefore.theraphist.lastName}{" "}
                {timelineObject.originalBefore.theraphist.firstName}
              </li>
              <li>
                Wizyta:{" "}
                {moment(timelineObject.originalBefore.dateTime.toDate()).format(
                  "YYYY-MM-DD HH:mm"
                )}
              </li>
              <li>
                Lokalizacja: {timelineObject.originalBefore.location.name}
              </li>
              <li>
                Klient: {timelineObject.originalBefore.customer.lastName}{" "}
                {timelineObject.originalBefore.customer.firstName}
              </li>
            </ul>
          )}
          <hr style={{ width: 200 }} />
          <div className="d-flex align-items-center">
            <ul className="list-unstyled">
              {changesets
                .diff(timelineObject.before, timelineObject.after)
                .map((d: any) => (
                  <li className="mb-75">
                    <span className="fw-bolder me-25">{d.key}: </span>
                    {(d.type === "update" || d.type === "delete") && (
                      <span>
                        {translate(d.key, d.oldValue) ||
                          (d.oldValue !== "" && "-")}
                      </span>
                    )}
                    {d.type === "update" && d.oldValue !== "" && (
                      <span> {"->"} </span>
                    )}
                    {d.type !== "delete" && (
                      <span>{translate(d.key, d.value) || "-"}</span>
                    )}
                  </li>
                ))}
            </ul>
          </div>
        </div>
      ),
    });
  }

  return timelineContent;
};

const updateTimeline = (
  transactions: QueryDocumentSnapshot<DocumentData>[]
): any[] => {
  const timelineObjects: any[] = [];

  transactions.forEach((doc) => {
    const timelineObject = createTimelineObject(doc);
    const timelineContent =
      generateTimelineContentAppointmentChange(timelineObject);

    if (timelineContent.length > 0) {
      timelineObjects.push(...timelineContent);
    }
  });
  console.log(timelineObjects);
  return _.orderBy(timelineObjects, "dateTime", "desc");
};

// ** Slice
export const feedStore = createSlice({
  name: "appointmentDetails",
  initialState,
  reducers: {
    // setTransactions(
    //   state,
    //   action: PayloadAction<{
    //     transactions: QueryDocumentSnapshot<DocumentData>[];
    //   }>
    // ) {
    //   state.transactions = action.payload.transactions;
    //   state.timeline = updateTimeline(action.payload.transactions);
    //   state.updatedAt = Date.now().toString();
    // },
    setTransactions(
      state,
      action: PayloadAction<{
        transactions: QueryDocumentSnapshot<DocumentData>[];
        hasMore: boolean;
      }>
    ) {
      state.transactions = [
        ...state.transactions,
        ...action.payload.transactions,
      ];
      state.timeline = updateTimeline(state.transactions);
      state.updatedAt = Date.now().toString();
      state.hasMore = action.payload.hasMore;
      state.loading = false;
    },
    resetTransactions(state) {
      state.transactions = [];
      state.timeline = [];
      state.hasMore = true;
      state.loading = false;
    },
  },
});

export const { setTransactions, resetTransactions } = feedStore.actions;

const subscribeToRealTimeFeedSubscription: any = {
  sub: null,
};
export const subscribeToRealTimeFeed = (limitCount: number) => {
  if (subscribeToRealTimeFeedSubscription.sub) {
    subscribeToRealTimeFeedSubscription.sub();
  }

  try {
    const queryRef = query(
      collection(db, "transactions"),
      where("context.params.collectionId", "==", "appointments"),
      orderBy("changedAt", "desc"),
      limit(limitCount)
    );

    subscribeToRealTimeFeedSubscription.sub = onSnapshot(
      queryRef,
      (snapshot) => {
        if (!snapshot.empty) {
          store.dispatch(
            setTransactions({
              transactions: snapshot.docs,
              hasMore: snapshot.docs.length === limitCount,
            })
          );
        }
      }
    );
  } catch (e) {
    console.error(e);
  }
};

export default feedStore.reducer;
