import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  collection,
  getDocs,
  onSnapshot,
  query,
  QueryConstraint,
  where,
} from "firebase/firestore";
import { orderBy } from "lodash";
import moment from "moment-timezone";
import type { Appointment } from "practicare/types/appointments.model";
import type { User } from "practicare/types/user.model";
import { getCollectionName } from "src/config/utils";
import { db } from "../../config/firebase";
import { store } from "../store";

interface AppointmentsSubscription {
  sub: (() => void) | null;
  start: Date | null;
  end: Date | null;
  theraphistId: string | null;
  userId: string | null;
  loading: boolean;
  status: string | null;
}

export const appointmentsSubscription: AppointmentsSubscription = {
  sub: null,
  start: null,
  end: null,
  theraphistId: null,
  userId: null,
  loading: false,
  status: "",
};

export const userAppointmentsSubscription = {
  sub: null as (() => void) | null,
  start: null as Date | null,
  end: null as Date | null,
  userId: null as string | null,
  loading: false as boolean,
};

export interface AppointmentsState {
  data: Appointment[];
  singleUserData: Appointment[];
  updatedAt: string;
}

const initialState: AppointmentsState = {
  data: [],
  singleUserData: [],
  updatedAt: Date.now().toString(),
};

const appointmentsSlice = createSlice({
  name: "appointments",
  initialState,
  reducers: {
    setData(state, action: PayloadAction<Appointment[]>) {
      state.data = action.payload;
      state.updatedAt = Date.now().toString();
    },
    setUserCalendarData(state, action: PayloadAction<Appointment[]>) {
      state.singleUserData = action.payload;
      state.updatedAt = Date.now().toString();
    },
  },
});

const resetSubscription = () => {
  if (appointmentsSubscription.sub) {
    appointmentsSubscription.sub();
  }
};

const shouldUpdateSubscription = (
  start: Date,
  end: Date,
  theraphistId: string,
  user: User,
  status: string
) => {
  return (
    (start && appointmentsSubscription.start?.getTime() !== start.getTime()) ||
    (end && appointmentsSubscription.end?.getTime() !== end.getTime()) ||
    (theraphistId
      ? appointmentsSubscription.theraphistId !== theraphistId
      : true) ||
    (user ? appointmentsSubscription.userId !== user.id : true) ||
    appointmentsSubscription.status !== status
  );
};

const buildConstraints = (
  start: Date,
  end: Date,
  theraphistId: string,
  user: User,
  status: string
): QueryConstraint[] => {
  const constraints: QueryConstraint[] = [
    where("isDeleted", "==", status === "deleted"),
  ];

  if (start) constraints.push(where("dateTime", ">=", start));
  if (end) constraints.push(where("dateTime", "<=", end));

  if (user && user.role !== "ADMIN") {
    constraints.push(where("theraphist.id", "==", user.id));
  } else if (theraphistId) {
    constraints.push(where("theraphist.id", "==", theraphistId));
  }

  return constraints;
};

const handleSnapshot = (snapshot: any) => {
  const appointments: Appointment[] = [];
  snapshot.docs.forEach((doc: any) => {
    if (doc.data().appointmentType?.key !== "RESERVATION") {
      appointments.push({
        ...doc.data(),
        id: doc.id,
        dateTime: doc.data().dateTime.toDate(),
      });
    }
  });

  const ordered = orderBy(appointments, "dateTime");
  store.dispatch(appointmentsSlice.actions.setData(ordered));
  appointmentsSubscription.loading = false;
};

export const subscribeToAppointments = (
  start: Date,
  end: Date,
  theraphistId: string,
  user: User,
  status: string
) => {
  appointmentsSubscription.loading = true;
  if (user && user.role) {
    if (shouldUpdateSubscription(start, end, theraphistId, user, status)) {
      resetSubscription();
      store.dispatch(appointmentsSlice.actions.setData([]));

      appointmentsSubscription.start = start;
      appointmentsSubscription.end = end;
      appointmentsSubscription.theraphistId = theraphistId;
      appointmentsSubscription.userId = user?.id;
      appointmentsSubscription.status = status;

      const constraints = buildConstraints(
        start,
        end,
        theraphistId,
        user,
        status
      );
      const collectionName = getCollectionName(
        "appointments",
        store,
        user.role === "ADMIN"
      );

      try {
        appointmentsSubscription.sub = onSnapshot(
          query(collection(db, collectionName), ...constraints),
          handleSnapshot
        );
      } catch (e) {
        console.error(e);
      }
    } else {
      appointmentsSubscription.loading = false;
    }
  }
};
export const fetchUserAppointmentsForDate = async (
  userId: string,
  date?: Date,
  isAdmin?: boolean
) => {
  try {
    console.log("fetching");
    const collectionName = getCollectionName("appointments", store, isAdmin);

    const constraints: QueryConstraint[] = [
      where("isDeleted", "==", false),
      where("theraphist.id", "==", userId),
    ];
    if (date) {
      constraints.push(
        where("dateTime", ">=", moment(date).startOf("day").toDate())
      );
      constraints.push(
        where("dateTime", "<=", moment(date).endOf("day").toDate())
      );
    }
    const q = query(collection(db, collectionName), ...constraints);
    const querySnapshot = await getDocs(q);
    const appointments: Appointment[] = [];
    querySnapshot.forEach((doc) => {
      if (!doc.data().isDeleted) {
        appointments.push({
          ...(doc.data() as Appointment),
          id: doc.id,
          dateTime: doc.data().dateTime.toDate(),
        });
      }
    });
    return appointments;
  } catch (e) {
    console.error(e);
    return [];
  }
};
export const subscribeToUserCalendar = (
  start: Date,
  end: Date,
  userId: string
) => {
  if (userAppointmentsSubscription.sub) {
    if (
      start &&
      userAppointmentsSubscription.start &&
      userAppointmentsSubscription.start.getTime() !== start.getTime()
    ) {
      userAppointmentsSubscription.sub();
    } else if (
      end &&
      userAppointmentsSubscription.end &&
      userAppointmentsSubscription.end.getTime() !== end.getTime()
    ) {
      userAppointmentsSubscription.sub();
    } else if (userId && userAppointmentsSubscription.userId !== userId) {
      userAppointmentsSubscription.sub();
    } else {
      return;
    }
  }
  userAppointmentsSubscription.start = start;
  userAppointmentsSubscription.end = end;
  userAppointmentsSubscription.userId = userId;
  const constrainsAppointments = [where("isDeleted", "==", false)];

  if (start) constrainsAppointments.push(where("dateTime", ">=", start));
  if (end) constrainsAppointments.push(where("dateTime", "<=", end));
  constrainsAppointments.push(where("theraphist.id", "==", userId));

  const collectionName = getCollectionName("appointments", store, false);

  try {
    userAppointmentsSubscription.sub = onSnapshot(
      query(collection(db, collectionName), ...constrainsAppointments),
      (data) => {
        const appointments: Appointment[] = [];
        data.forEach((doc) => {
          appointments.push({
            ...(doc.data() as Appointment),
            id: doc.id,
            dateTime: moment(doc.data().dateTime.toDate()).toDate(),
          });
        });
        store.dispatch(
          appointmentsSlice.actions.setUserCalendarData(
            orderBy(appointments, "dateTime")
          )
        );
        return appointments;
      }
    );
  } catch (e) {
    console.error(e);
  }
};
export default appointmentsSlice.reducer;
