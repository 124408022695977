// ** Redux Imports
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  collection,
  doc,
  DocumentData,
  onSnapshot,
  orderBy,
  query,
  QueryDocumentSnapshot,
  where,
} from "firebase/firestore";
import _, { isEmpty } from "lodash";
import moment from "moment-timezone";
import type { Appointment } from "practicare/types/appointments.model";
import { AtSign, Calendar, StopCircle } from "react-feather";
import { Link } from "react-router-dom";
import { getCollectionName } from "src/config/utils";
import { db } from "../../config/firebase";
import { store } from "../store";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const changesets = require("diff-json");
// ** Constants
const TITLE_CHANGE_DETAILS = "Zmiana szczegółów wizyty";
const PENDING_STATUS = "Pending";

const notficicationStrings: any = {
  EMAIL_CHANGE_THERAPHIST: {
    title: "Notyfikacja - Email",
    noPermissionContent:
      "Brak pozwoleń do wysyłania emaili. Email z informacją o zmianie terminu nie został wysłany do terapeuty.",
    content:
      "Email z informacją o zmianie terminu został wysłany do terapeuty.",
  },
  EMAIL_AFTER_FIRST_VISIT: {
    title: "Notyfikacja - Email",
    noPermissionContent:
      "Brak pozwoleń do wysyłania emaili. Email po pierwszej wizycie nie został wysłany do klienta.",
    content: "Email po pierwszej wizycie został wysłany do klienta.",
  },
  EMAIL_CHANGE_CUSTOMER: {
    title: "Notyfikacja - Email",
    noPermissionContent:
      "Brak pozwoleń do wysyłania emaili. Email z informacją o zmianie terminu nie został wysłany do klienta.",
    content: "Email z informacją o zmianie terminu został wysłany do klienta.",
  },
  EMAIL_PAYMENT_ZERO: {
    title: "Notyfikacja - Email",
    noPermissionContent:
      "Brak pozwoleń do wysyłania emaili. Email z linkiem do płatności nie został wysłany.",
    content: "Email z linkiem z przypomnieniem o wizycie został wysłany.",
  },
  EMAIL_PAYMENT: {
    title: "Notyfikacja - Email",
    noPermissionContent:
      "Brak pozwoleń do wysyłania emaili. Email z linkiem do płatności nie został wysłany.",
    content: "Email z linkiem do płatności został wysłany.",
  },
  EMAIL_MEETING_INVITE: {
    title: "Notyfikacja - Email",
    noPermissionContent:
      "Brak pozwoleń do wysyłania emaili. Email z linkiem do wizyty online nie został wysłany.",
    content: "Email z linkiem do wizyty online został wysłany.",
  },
  EMAIL_FIRST_APPOINTMENT: {
    title: "Notyfikacja - Email",
    noPermissionContent:
      "Brak pozwoleń do wysyłania emaili. Email z powitaniem w ośrodku nie został wysłany.",
    content: "Email z powitaniem w ośrodku został wysłany.",
  },
  EMAIL_NEW_CUSTOMER: {
    title: "Notyfikacja - Email",
    noPermissionContent:
      "Brak pozwoleń do wysyłania emaili. Email z informacją do terapeuty o nowym kliencie nie został wysłany.",
    content: "Email z informacją do terapeuty o nowym kliencie został wysłany.",
  },
  EMAIL_CANCELLED: {
    title: "Notyfikacja - Email",
    noPermissionContent:
      "Brak pozwoleń do wysyłania emaili. Email z informacją o odwołaniu nie został wysłany.",
    content: "Email z informacją do terapeuty o odwołaniu wizyty wysłany.",
  },
  VARIANT_CHANGE: {
    title: "Notyfikacja - Email",
    noPermissionContent:
      "Brak pozwoleń do wysyłania emaili. Email z informacją o zmianie wariantu wizyty nie został wysłany do terapeuty.",
    content:
      "Email z informacją o zmianie wariantu wizyty został wysłany do terapeuty.",
  },
  EMAIL_CANCELLED_NOT_PAID: {
    title: "Notyfikacja - Email",
    noPermissionContent: "",
    content:
      "Email z informacją o automatycznym odwołaniu wizyty został wysłany do klienta.",
  },
  EMAIL_PAYMENT_CONFIRMATION_THERAPHIST: {
    title: "Notyfikacja - Email",
    noPermissionContent:
      "Brak pozwoleń do wysyłania emaili. Email z informacją do terapeuty o płanotści nie został wysłany.",
    content:
      "Email z informacją do terapeuty o odnotowaniu płatności przez system Przelewy24 za wizytę został wysłany.",
  },
  EMAIL_PAYMENT_CONFIRMATION_CUSTOMER: {
    title: "Notyfikacja - Email",
    noPermissionContent:
      "Brak pozwoleń do wysyłania emaili. Email z informacją do klienta o płanotści nie został wysłany.",
    content:
      "Email z informacją do klienta o odnotowaniu płatności przez system Przelewy24 za wizytę został wysłany.",
  },
  SMS_AFTER_FIRST_VISIT: {
    title: "Notyfikacja - SMS",
    noPermissionContent:
      "Brak pozwoleń do wysyłania sms. SMS po pierszej wizycie nie został wysłany.",
    content: "SMS po pierwszej wizycie został wysłany z wiadomością poniżej:",
  },
  SMS_REMINDER_FIRST_VISIT: {
    title: "Notyfikacja - SMS",
    noPermissionContent:
      "Brak pozwoleń do wysyłania sms. SMS z przypomnieniem o pierszej wizycie nie został wysłany.",
    content:
      "SMS z przypomnieniem o pierwszej wizycie został wysłany z wiadomością poniżej:",
  },
  SMS_REMINDER: {
    title: "Notyfikacja - SMS",
    noPermissionContent:
      "Brak pozwoleń do wysyłania sms. SMS z przypomnieniem o wizycie nie został wysłany.",
    content:
      "SMS z przypomnieniem o wizycie został wysłany z wiadomością poniżej:",
  },
  SMS_NEW_CUSTOMER: {
    title: "Notyfikacja - SMS",
    noPermissionContent:
      "Brak pozwoleń do wysyłania emaili. SMS z informacją o nowym kliencie nie został wysłany.",
    content: "SMS z informacją o nowym kliencie wysłany z wiadomością poniżej:",
  },
};
// ** Types
interface TimelineObject {
  id: string;
  before?: Record<string, any>;
  originalBefore?: any;
  after?: Record<string, any>;
  originalAfter?: any;
  changedAt?: Date | string;
  type: string;
  updatedBy: any;
  createdBy: any;
  changer: Changer;
  isCompletedAt?: Date | null;
  noPermission?: boolean;
  customContent?: string;
}

interface Changer {
  id: string;
  display: string;
  isCustomer?: boolean;
}

export interface AppointmentDetailsState {
  data: Appointment | null;
  notifications: QueryDocumentSnapshot<DocumentData>[];
  transactions: QueryDocumentSnapshot<DocumentData>[];
  timeline: any[];
  updatedAt: string;
}

// ** Initial State
const initialState: AppointmentDetailsState = {
  data: null,
  notifications: [],
  transactions: [],
  timeline: [],
  updatedAt: Date.now().toString(),
};

// ** Translate Function
const translate = (type: string, value: string): string => {
  const dictionary: any = {
    "Rodzaj płaności": {
      ONLINE: "Online",
      OFFLINE: "Stacjonarnie",
      BANK_TRANSFER: "Przelew bankowy",
      CREDIT_CARD: "Karta kredytowa",
      CASH: "Gotówka",
      comment: "Komentarz terapeuty",
      "N/A": "N/A",
    },
    "Status płatności": {
      COMPLETED_AUTOMATICALLY: "Opłacono",
      COMPLETED_MANUALLY: "Opłacono",
      PENDING: "Oczekuje",
    },
    Status: {
      SCHEDULED: "Zaplanowana",
      COMPLETED: "Zrealizowana",
      CANCELED: "Odwołana",
      "CANCELED-PAID": "Odwołana poza terminem",
    },
    "Powód odwołania": {
      CUSTOMER: "Odwołanie klienta",
      APPOINTMENT_MOVED: "Przełożenie wizyty na inny termin",
      THERAPIST_ILLNESS: "Choroba terapeuty",
      THERAPIST_VACATION: "Urlop terapeuty",
      PROCESS_TERMINATION: "Zakończenie procesu",
      CUSTOMER_ABSENT: "Klient nie pojawił się na wizycie",
    },
    "Powód zmiany terminu": {
      CUSTOMER: "Odwołanie klienta",
      THERAPIST_ILLNESS: "Choroba terapeuty",
      THERAPIST_VACATION: "Urlop terapeuty",
      THERAPIST_CHANGE: "Przelozona przez terapeutę",
      THERAPY_RESIGNATION: "Rezygnacja z terapii",
      PROCESS_TERMINATION: "Zakończenie procesu",
      CUSTOMER_ABSENT: "Klient nie pojawił się na wizycie",
    },
  };

  return dictionary[type] ? dictionary[type][value] : value;
};

// ** Helper Functions
const createTimelineObject = (
  doc: QueryDocumentSnapshot<DocumentData>
): TimelineObject => {
  const timelineObject: TimelineObject = {
    id: doc.id,
    before: {},
    after: {},
    originalBefore: doc.data().before,
    originalAfter: doc.data().after,
    changedAt: doc.data().changedAt?.toDate(),
    type: "APPOINTMENT",
    updatedBy: doc.data().after?.updatedBy,
    createdBy: doc.data().before?.createdBy,
    changer: {
      id: "",
      display: "",
      isCustomer: false,
    },
  };

  if (!isEmpty(doc.data().before)) {
    timelineObject.before = {
      Klient:
        doc.data().before.customer?.lastName &&
        doc.data().before.customer?.firstName
          ? `${doc.data().before.customer.lastName} ${doc.data().before.customer.firstName}`
          : "",
      Lokalizacja: doc.data().before.location?.name || "",
      Pokój: doc.data().before.room?.name || "",
      "Komentarz terapeuty": doc.data().before.comment || "",
      "Kwota rabatu": doc.data().before.discountAmount || "" || "",
      Status: doc.data().before.appointmentStatus || "",
      "Powód odwołania": doc.data().before.cancelReason || "",
      "Powód zmiany terminu": doc.data().before.dateChangeReason || "",
      Wariant: doc.data().before.variant?.variant?.name || "",
      "Status płatności": doc.data().before.paymentStatus || "",
      "Rodzaj płaności": doc.data().before.paymentType || "",
      "Data i godzina wizyty": moment(
        doc.data().before.dateTime?.toDate()
      ).format("YYYY-MM-DD HH:mm"),
    };
  }

  if (!isEmpty(doc.data().after)) {
    timelineObject.after = {
      Klient:
        doc.data().after?.customer?.lastName &&
        doc.data().after?.customer?.firstName
          ? `${doc.data().after.customer.lastName} ${doc.data().after.customer.firstName}`
          : "",
      Lokalizacja: doc.data().after.location?.name || "",
      "Komentarz terapeuty": doc.data().after.comment || "",
      "Kwota rabatu": doc.data().after.discountAmount || "",
      Pokój: doc.data().after.room?.name || "",
      Status: doc.data().after.appointmentStatus || "",
      Wariant: doc.data().after.variant?.variant?.name || "",
      "Powód odwołania": doc.data().after.cancelReason || "",
      "Powód zmiany terminu": doc.data().after.dateChangeReason || "",
      "Status płatności": doc.data().after.paymentStatus || "",
      "Rodzaj płaności": doc.data().after.paymentType || "",
      "Data i godzina wizyty": moment(
        doc.data().after.dateTime?.toDate()
      ).format("YYYY-MM-DD HH:mm"),
    };
  }

  if (timelineObject.originalAfter.updatedBy) {
    if (timelineObject.originalAfter.updatedBy === "CUSTOMER") {
      timelineObject.changer.isCustomer = true;
      timelineObject.changer.id = timelineObject.after?.customer?.id;
      timelineObject.changer.display = `${timelineObject.originalAfter.customer?.lastName} ${
        timelineObject.originalAfter.customer?.firstName
      }`;
    } else {
      timelineObject.changer.id = timelineObject.originalAfter?.updatedBy?.id;
      timelineObject.changer.display = `${timelineObject.originalAfter.updatedBy?.lastName} ${
        timelineObject.originalAfter.updatedBy?.firstName
      }`;
    }
  } else {
    timelineObject.changer.id = timelineObject.originalAfter?.createdBy?.id;
    timelineObject.changer.display = `${timelineObject.originalAfter?.createdBy?.lastName} ${timelineObject.originalAfter?.createdBy?.firstName}`;
  }
  return timelineObject;
};

const generateTimelineContentAppointmentChange = (
  timelineObject: TimelineObject
): any[] => {
  const timelineContent: any[] = [];

  const differenceList = changesets.diff(
    timelineObject.before,
    timelineObject.after
  );

  if (differenceList.length > 0) {
    timelineContent.push({
      title: (
        <div>
          <span>{TITLE_CHANGE_DETAILS}</span>
          {timelineObject.changedAt && (
            <span>
              {" - "}
              <Link
                target="_blank"
                rel="noopener noreferrer"
                className="text-body ml-1 practicare-link"
                to={
                  timelineObject.changer.id !== "system"
                    ? `/${timelineObject.changer.isCustomer ? "customers" : "users"}/${timelineObject.changer.id}`
                    : ""
                }
              >
                {timelineObject.changer.display}
              </Link>
            </span>
          )}
        </div>
      ),
      icon: <Calendar size={14} />,
      meta: timelineObject.changedAt
        ? moment(timelineObject.changedAt).format("YYYY-MM-DD HH:mm")
        : PENDING_STATUS,
      dateTime: timelineObject.changedAt,
      customContent: (
        <div className="d-flex align-items-center">
          <ul className="list-unstyled">
            {changesets
              .diff(timelineObject.before, timelineObject.after)
              .map((d: any) => (
                <li className="mb-75">
                  <span className="fw-bolder me-25">{d.key}: </span>
                  {(d.type === "update" || d.type === "delete") && (
                    <span>
                      {translate(d.key, d.oldValue) ||
                        (d.oldValue !== "" && "-")}
                    </span>
                  )}
                  {d.type === "update" && d.oldValue !== "" && (
                    <span> {"->"} </span>
                  )}
                  {d.type !== "delete" && (
                    <span>{translate(d.key, d.value) || "-"}</span>
                  )}
                </li>
              ))}
          </ul>
        </div>
      ),
    });
  }

  return timelineContent;
};

const updateTimeline = (
  notifications: QueryDocumentSnapshot<DocumentData>[],
  transactions: QueryDocumentSnapshot<DocumentData>[],
  isAdmin: boolean
): any[] => {
  const timelineObjects: any[] = [];

  if (isAdmin) {
    transactions.forEach((doc) => {
      const timelineObject = createTimelineObject(doc);
      const timelineContent =
        generateTimelineContentAppointmentChange(timelineObject);

      if (timelineContent.length > 0) {
        timelineObjects.push(...timelineContent);
      }
    });
  }

  notifications.forEach((doc) => {
    const timelineObject: TimelineObject = {
      ...(doc.data() as TimelineObject),
      id: doc.id,
      isCompletedAt:
        (doc.data().isCompletedAt && doc.data().isCompletedAt.toDate()) ||
        doc.data().createdAt?.toDate() ||
        "-",
      customContent: doc.data().smsContent || "",
      updatedBy: null,
      createdBy: doc.data().createdBy,
      changer: {
        id: doc.data().createdBy?.id || "",
        isCustomer: false,
        display: doc.data().createdBy
          ? `${doc.data().createdBy.lastName} ${doc.data().createdBy.firstName}`
          : "System",
      },
    };
    console.log(timelineObject.type);
    timelineObjects.push({
      title: (
        <div>
          <span>{notficicationStrings[timelineObject.type]?.title}</span>
          {timelineObject.changer.id && (
            <span>
              {" - "}
              <Link
                target="_blank"
                rel="noopener noreferrer"
                className="text-body ml-1 practicare-link"
                to={
                  timelineObject.changer.id !== "system"
                    ? `/users/${timelineObject.changer.id}`
                    : "#"
                }
              >
                {timelineObject.changer.display}
              </Link>
            </span>
          )}
        </div>
      ),
      content: !timelineObject.noPermission
        ? notficicationStrings[timelineObject.type]?.content
        : notficicationStrings[timelineObject.type]?.noPermissionContent,
      dateTime: timelineObject.isCompletedAt,
      customContent:
        timelineObject.customContent && !timelineObject.noPermission ? (
          <div className="d-flex align-items-center">
            <span>{timelineObject.customContent}</span>
          </div>
        ) : null,
      icon: timelineObject.noPermission ? (
        <StopCircle size={14} />
      ) : (
        <AtSign size={14} />
      ),
      meta: timelineObject.isCompletedAt
        ? moment(timelineObject.isCompletedAt).format("YYYY-MM-DD HH:mm")
        : PENDING_STATUS,
    });

    // Other notification types can be added similarly

    const timelineContent =
      generateTimelineContentAppointmentChange(timelineObject);

    if (timelineContent.length > 0) {
      timelineObjects.push(...timelineContent);
    }
  });

  return _.orderBy(timelineObjects, "dateTime", "desc");
};

// ** Slice
export const appointmentDetailsSlice = createSlice({
  name: "appointmentDetails",
  initialState,
  reducers: {
    setAppointment(state, action: PayloadAction<any>) {
      state.data = action.payload;
      state.updatedAt = Date.now().toString();
    },
    setNotifications(
      state,
      action: PayloadAction<{
        notifications: QueryDocumentSnapshot<DocumentData>[];
        isAdmin: boolean;
      }>
    ) {
      state.notifications = action.payload.notifications;
      state.timeline = updateTimeline(
        action.payload.notifications,
        state.transactions,
        action.payload.isAdmin
      );
      state.updatedAt = Date.now().toString();
    },
    setTransactions(
      state,
      action: PayloadAction<{
        transactions: QueryDocumentSnapshot<DocumentData>[];
        isAdmin: boolean;
      }>
    ) {
      state.transactions = action.payload.transactions;
      state.timeline = updateTimeline(
        state.notifications,
        action.payload.transactions,
        action.payload.isAdmin
      );
      state.updatedAt = Date.now().toString();
    },
  },
});

export const appointmentDetailsSubscription = {
  subAppointment: null,
  subNotifications: null,
  subTransactions: null,
  appointmentId: "",
  loading: false,
} as any;
// ** Subscription
export const subscribeToAppointmentDetails = (
  appointmentId: string,
  isAdmin: boolean
) => {
  if (appointmentId === "") {
    appointmentDetailsSubscription.loading = true;
    appointmentDetailsSubscription.appointmentId = false;
    appointmentDetailsSlice.actions.setAppointment(null);
    if (appointmentDetailsSubscription.subAppointment) {
      appointmentDetailsSubscription.subAppointment();
    }
    if (appointmentDetailsSubscription.subNotifications) {
      appointmentDetailsSubscription.subNotifications();
    }
    if (appointmentDetailsSubscription.subTransactions) {
      appointmentDetailsSubscription.subTransactions();
    }
    return;
  }
  if (appointmentDetailsSubscription.subAppointment) {
    if (
      appointmentId &&
      appointmentDetailsSubscription.appointmentId &&
      appointmentDetailsSubscription.appointmentId !== appointmentId
    ) {
      appointmentDetailsSubscription.subAppointment();
      appointmentDetailsSubscription.subNotifications();
      if (appointmentDetailsSubscription.subTransactions) {
        appointmentDetailsSubscription.subTransactions();
      }
    } else {
      return;
    }
  }

  appointmentDetailsSubscription.loading = true;

  appointmentDetailsSubscription.appointmentId = appointmentId;
  const collectionName = getCollectionName("appointments", store, isAdmin);
  try {
    appointmentDetailsSubscription.subAppointment = onSnapshot(
      doc(db, `${collectionName}/${appointmentId}`),
      (data) => {
        if (data.exists()) {
          store.dispatch(
            appointmentDetailsSlice.actions.setAppointment({
              id: data.id,
              ...data.data(),
              dateTime: data.data().dateTime.toDate(),
            })
          );
          appointmentDetailsSubscription.loading = false;
        }
      }
    );
    if (isAdmin) {
      appointmentDetailsSubscription.subTransactions = onSnapshot(
        query(
          collection(db, "transactions"),
          where("context.params", "==", {
            collectionId: "appointments",
            documentId: appointmentId,
          }),
          orderBy("changedAt", "desc")
        ),
        (data) => {
          store.dispatch(
            appointmentDetailsSlice.actions.setTransactions({
              transactions: data.empty ? [] : data.docs,
              isAdmin,
            })
          );
        }
      );
    }
    appointmentDetailsSubscription.subNotifications = onSnapshot(
      query(
        collection(db, "notifications"),
        where("appointementId", "==", appointmentId)
      ),
      (data) => {
        store.dispatch(
          appointmentDetailsSlice.actions.setNotifications({
            notifications: data.empty ? [] : data.docs,
            isAdmin,
          })
        );
      }
    );
  } catch (e) {
    console.error(e);
  }
};

export default appointmentDetailsSlice.reducer;
